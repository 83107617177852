<template>
  <n-modal v-model:show="modalStore.showDemoModal" class="width-[600px] height-[200px]" title="选择体验版本">
    <div class="demo-options flex flex-col md:flex-row bg-white gap-6 p-4 md:p-8 w-full md:w-[800px] h-auto md:h-[350px] border-radius-lg border-gray-200 rounded-lg">
      <!-- 社区版 -->
      <div class="demo-option flex-1 p-4 border rounded-lg hover:shadow-md transition-all flex flex-col">
        <h3 class="text-xl md:text-2xl font-bold text-center mb-2">社区版</h3>
        <p class="text-sm md:text-base text-gray-600 text-center">开源免费版本，使用vue2技术栈，支持基础表单功能</p>
        <img src="https://doc-oss.tduckcloud.com/doc/20250306173620.png" alt="TDuckX" class="p-2 md:p-4 w-full object-contain">
        <div class="flex-1 flex items-end justify-center mt-4">
          <button class="w-full md:w-auto px-4 md:px-6 py-2 text-white bg-[#4C4ED3] hover:bg-[#4C4ED3]/80 !rounded-button whitespace-nowrap" @click="goToDemo('tduck')" >
            立即体验
          </button>
        </div>
      </div>
      
      <!-- TDuckX -->
      <div class="demo-option flex-1 p-4 border rounded-lg hover:shadow-md transition-all flex flex-col">
        <h3 class="text-xl md:text-2xl font-bold text-center mb-2">TDuckX 🔥</h3>
        <p class="text-sm md:text-base text-gray-600 text-center">功能最强，Vue3技术栈，支持表单/考试/审批流/测评/核销/360°测评等</p>
        <img src="https://doc-oss.tduckcloud.com/doc/20250306173122.png" alt="TDuckX" class="p-2 md:p-4 w-full object-contain">
        <div class="flex-1 flex items-end justify-center mt-4">
          <button class="w-full md:w-auto px-4 md:px-6 py-2 text-white bg-[#4C4ED3] hover:bg-[#4C4ED3]/80 !rounded-button whitespace-nowrap" @click="goToDemo('tduckx')" >
            立即体验
          </button>
        </div>
      </div>
      
      <!-- TDuckPro -->
      <div class="demo-option flex-1 p-4 border rounded-lg hover:shadow-md transition-all flex flex-col">
        <h3 class="text-xl md:text-2xl font-bold text-center mb-2">TDuckPro</h3>
        <p class="text-sm md:text-base text-gray-600 text-center">使用Vue2技术栈，支持表单/考试/审批流功能</p>
        <img src="https://doc-oss.tduckcloud.com/doc/20250306173307.png" alt="TDuckX" class="p-2 md:p-4 w-full object-contain">
        <div class="flex-1 flex items-end justify-center mt-4">
          <button class="w-full md:w-auto px-4 md:px-6 py-2 text-white bg-[#4C4ED3] hover:bg-[#4C4ED3]/80 !rounded-button whitespace-nowrap" @click="goToDemo('tduckpro')" >
            立即体验
          </button>
        </div>
      </div>
    </div>
  </n-modal>
</template>

<script setup lang="ts">
import { useModalStore } from '@/stores/modal'

const modalStore = useModalStore()

const goToDemo = (version: string) => {
  if (version === 'tduck') {
    window.open(`https://demo.tduckapp.com`, '_blank')
  } else if (version === 'tduckx') {
    window.open(`https://x.tduckcloud.com`, '_blank')
  } else if (version === 'tduckpro') {
    window.open(`https://pro.tduckcloud.com`, '_blank')
  }
}
</script>

<style scoped>
/* 添加移动端样式 */
@media (max-width: 768px) {
  .demo-options {
    max-height: 80vh;
    overflow-y: auto;
  }

  .demo-option {
    min-height: 300px;
  }

  .demo-option img {
    max-height: 150px;
    object-fit: contain;
  }

  /* 优化滚动条样式 */
  .demo-options::-webkit-scrollbar {
    width: 4px;
  }

  .demo-options::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .demo-options::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  .demo-options::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

/* 针对更小屏幕的优化 */
@media (max-width: 480px) {
  .demo-option {
    min-height: 280px;
  }

  .demo-option h3 {
    font-size: 1.25rem;
  }

  .demo-option p {
    font-size: 0.875rem;
  }

  .demo-option button {
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
  }
}
</style> 